import Navbar from '../../library/Navbar/Navbar'
import Button from '../../library/Button/Button'
import ParticleFlow from '../../webgl/ParticleFlow'

const Home: React.FC = () => {
  return (
    <div className="relative min-h-screen w-full flex flex-col items-center justify-center px-8 bg-gradient-panel">
      <Navbar />
      <div className="relative z-10">
        <div className="flex flex-col items-center max-w-[550px]">
          <h1 className="mb-6 text-3xl sm:text-5xl leading-normal sm:leading-snug font-light tracking-wide text-text-1 text-center font-nippo">
            The Fastest Way to Fix Your Errors
          </h1>
          <p className="mb-16 text-subtitle text-text-2 text-center max-w-[500px]">
            Vigilant is a tool for monitoring errors and logs. Get instant
            alerts when your users hit issues, and relevant context to fix it.
          </p>
          <div className="flex gap-4">
            <a href={`${process.env.REACT_APP_DASHBOARD_URL}/signup`}>
              <Button>Get Started</Button>
            </a>
          </div>
        </div>
      </div>
      <ParticleFlow />
    </div>
  )
}

export default Home
