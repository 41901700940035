import { ButtonHTMLAttributes, FC } from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'default' | 'outline'
}

const Button: FC<ButtonProps> = ({
  className = '',
  theme = 'default',
  ...props
}) => {
  const themeClasses = {
    default: 'bg-cta-base text-cta-text hover:bg-cta-hover',
    outline:
      'bg-background border border-panel-border text-text-1 hover:bg-panel-background',
  }

  return (
    <button
      className={`px-4 py-2 ${themeClasses[theme]} transition-colors rounded-md ${className}`}
      {...props}
    />
  )
}

export default Button
