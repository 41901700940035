import { useEffect, useRef } from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

const CONFIG = {
  // Animation
  ROTATION_SPEED: 0.0005,
  VERTICAL_STRETCH: 1.5,

  // Particles
  PARTICLE_SPEED: 0.001,
  PARTICLE_COUNT: 2000,
  PARTICLE_SIZE: 0.12,
  PARTICLE_COLOR: '#505050',

  // Geometry
  RADIUS: 9,
  NUM_PATHS: 32,

  // Camera
  FOV: 75,
  NEAR: 0.1,
  FAR: 1000,
  CAMERA_POSITION: [5, 5, 5] as const,

  // Initial rotation (in radians)
  INITIAL_ROTATION: {
    x: 0,
    y: Math.PI / 2,
    z: Math.PI * 2,
  },

  // Poles
  POLE_SIZE: 0.05,
  POLE_SEGMENTS: 16,
  POLE_COLOR: '#FFF',
}

export const ParticleFlow = () => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!containerRef.current) return
    const container = containerRef.current

    // Scene setup
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      CONFIG.FOV,
      container.clientWidth / container.clientHeight,
      CONFIG.NEAR,
      CONFIG.FAR
    )
    camera.position.set(...CONFIG.CAMERA_POSITION)

    // Create a group to hold all elements
    const group = new THREE.Group()
    scene.add(group)

    // Updated to use all rotation axes
    group.rotation.x = CONFIG.INITIAL_ROTATION.x
    group.rotation.y = CONFIG.INITIAL_ROTATION.y
    group.rotation.z = CONFIG.INITIAL_ROTATION.z

    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    })
    renderer.setSize(container.clientWidth, container.clientHeight)
    container.appendChild(renderer.domElement)

    // Orbit controls
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enableDamping = true

    // Animation setup
    const particleCount = CONFIG.PARTICLE_COUNT
    const radius = CONFIG.RADIUS
    const positions = new Float32Array(particleCount * 3)
    const initialPhiArray = new Float32Array(particleCount)
    const initialThetaArray = new Float32Array(particleCount)
    const geometry = new THREE.BufferGeometry()

    // Generate paths programmatically
    const paths = Array.from(
      { length: CONFIG.NUM_PATHS },
      (_, i) => (i * 2 * Math.PI) / CONFIG.NUM_PATHS
    )

    // Initialize particles
    for (let i = 0; i < particleCount; i++) {
      const pathIndex = Math.floor(Math.random() * paths.length)
      const phi = paths[pathIndex]
      initialPhiArray[i] = phi
      initialThetaArray[i] = Math.random() * Math.PI

      // Random starting position along the path
      const initialTheta = Math.random() * Math.PI
      positions[i * 3] = radius * Math.sin(initialTheta) * Math.cos(phi)
      positions[i * 3 + 1] =
        radius * Math.cos(initialTheta) * CONFIG.VERTICAL_STRETCH
      positions[i * 3 + 2] = radius * Math.sin(initialTheta) * Math.sin(phi)
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

    // Create a circular texture
    const texture = new THREE.CanvasTexture(
      (() => {
        const canvas = document.createElement('canvas')
        canvas.width = 64
        canvas.height = 64
        const context = canvas.getContext('2d')
        if (!context) return canvas

        // Draw a white circle
        context.beginPath()
        context.arc(32, 32, 30, 0, Math.PI * 2)
        context.fillStyle = 'white'
        context.fill()

        return canvas
      })()
    )

    const material = new THREE.PointsMaterial({
      color: CONFIG.PARTICLE_COLOR,
      size: CONFIG.PARTICLE_SIZE,
      sizeAttenuation: true,
      transparent: true,
      opacity: 0.8,
      map: texture,
    })

    const points = new THREE.Points(geometry, material)
    group.add(points)

    // Create pole markers
    const poleGeometry = new THREE.SphereGeometry(
      CONFIG.POLE_SIZE,
      CONFIG.POLE_SEGMENTS,
      CONFIG.POLE_SEGMENTS
    )
    const poleMaterial = new THREE.MeshBasicMaterial({
      color: new THREE.Color(CONFIG.POLE_COLOR),
    })

    // Update pole positions
    const northPole = new THREE.Mesh(poleGeometry, poleMaterial)
    northPole.position.set(0, radius * CONFIG.VERTICAL_STRETCH, 0)
    group.add(northPole)

    const southPole = new THREE.Mesh(poleGeometry, poleMaterial)
    southPole.position.set(0, -radius * CONFIG.VERTICAL_STRETCH, 0)
    group.add(southPole)

    // Animation loop
    let time = 0
    const animate = () => {
      requestAnimationFrame(animate)

      time += CONFIG.PARTICLE_SPEED
      const positions = points.geometry.attributes.position.array

      group.rotation.z += CONFIG.ROTATION_SPEED

      for (let i = 0; i < particleCount; i++) {
        const i3 = i * 3
        const phi = initialPhiArray[i]

        let theta = (initialThetaArray[i] + time * 0.5) % Math.PI

        positions[i3] = radius * Math.sin(theta) * Math.cos(phi)
        positions[i3 + 1] = radius * Math.cos(theta) * CONFIG.VERTICAL_STRETCH
        positions[i3 + 2] = radius * Math.sin(theta) * Math.sin(phi)
      }

      points.geometry.attributes.position.needsUpdate = true

      controls.update()
      renderer.render(scene, camera)
    }

    animate()

    // Handle window resize
    const handleResize = () => {
      camera.aspect = container.clientWidth / container.clientHeight
      camera.updateProjectionMatrix()
      renderer.setSize(container.clientWidth, container.clientHeight)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize)
      container.removeChild(renderer.domElement)
      geometry.dispose()
      material.dispose()
      poleGeometry.dispose()
      poleMaterial.dispose()
      renderer.dispose()
      controls.dispose()
    }
  }, [])

  return (
    <div
      ref={containerRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }}
    />
  )
}

export default ParticleFlow
