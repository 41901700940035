import { Menu, X } from 'lucide-react'
import { useState } from 'react'

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <nav className="fixed w-full top-0 left-0 right-0 flex items-center justify-between px-6 py-4 z-50 sm:px-16 bg-panel-background border-b border-panel-border">
      <a href="/" className="text-xl text-text-1 font-nippo tracking-wide">
        Vigilant
      </a>

      <div className="hidden sm:flex gap-8 text-text-1 text-body items-center">
        <a
          href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}
          className="hover:opacity-80"
        >
          Login
        </a>
        <a
          href={`${process.env.REACT_APP_DASHBOARD_URL}/signup`}
          className="hover:opacity-80"
        >
          Sign up
        </a>
      </div>

      <button
        className="sm:hidden text-text-1"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="absolute top-full left-0 right-0 bg-panel-background border-b border-panel-border flex flex-col h-[calc(100vh-100%)] sm:hidden">
          <a
            href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}
            className="text-text-1 hover:opacity-80 px-6 py-4 w-full"
          >
            Login
          </a>
          <a
            href={`${process.env.REACT_APP_DASHBOARD_URL}/signup`}
            className="text-text-1 hover:opacity-80 px-6 py-4 w-full"
          >
            Sign up
          </a>
        </div>
      )}
    </nav>
  )
}

export default Navbar
